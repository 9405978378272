import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormArrayOptions, FormService } from '../../services/form';
import { FieldType } from '../../services/field';
import { FieldBase } from '../../block/services/block';

export interface Video extends FieldBase {
  value: string;
}

export interface VideoOption {}

export interface VideoOptions {
  options: VideoOption[];
  formArray?: FormArrayOptions;
  newForm: (item: Video) => AbstractControl | undefined;
  newItem: Video;
  label: {
    placeholder: string;
    plural: string;
    singular: string;
  };
}

export interface VideoStyles {
  height: {
    px: number;
  };
  width: {
    px: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(private formService: FormService) {}

  get newItem(): Video {
    return {
      fieldType: FieldType.VIDEO,
      value: '',
    };
  }

  newForm = (item: Video): AbstractControl | undefined => {
    return this.formService.newForm(item);
  };

  get options(): VideoOptions {
    const options: VideoOptions = {
      formArray: {
        max: 1,
        min: 0,
      },
      label: {
        placeholder: '',
        plural: 'Videos',
        singular: 'Video',
      },
      newForm: this.newForm,
      newItem: this.newItem,
      options: [],
    };
    // for (let i = 0; i < options.formArray.max; i++) {
    //   options.options.push({})
    // }
    return options;
  }
}
