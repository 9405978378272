import { Inject, Injectable } from '@angular/core';
import { TransferItem } from 'ng-zorro-antd/transfer';
import firebase from 'firebase/compat/app';
import Timestamp = firebase.firestore.Timestamp;
import { Personalize } from '../personalize/service';
import { RxState } from '@rx-angular/state';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';

export interface Status {
  created: Timestamp;
  deleting: boolean;
  expires: Timestamp | null;
  passwordAuth: boolean;
  personalize: Personalize;
  personalized: boolean;
  protected: boolean;
  revised: Timestamp;
  session: string;
  unpublished: boolean;
}

export interface PublishedOptions {
  options: PublishedOption[];
  label: {
    singular: string;
  };
}

export interface PublishedType {
  label: 'Unpublish' | 'Protect' | 'Delete' | 'Personalize';
  value: boolean;
}

export type PublishedOption = 'unpublish' | 'protect' | 'delete' | 'personalize';
export type PublishedTypes = Record<PublishedOption, PublishedType>;

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {}

  get options(): PublishedOptions {
    return {
      label: {
        singular: 'Status',
      },
      options: ['unpublish', 'protect', 'personalize'],
    };
  }

  get types(): PublishedTypes {
    return {
      delete: {
        label: 'Delete',
        value: true,
      },
      protect: {
        label: 'Protect',
        value: true,
      },
      unpublish: {
        label: 'Unpublish',
        value: false,
      },
      personalize: {
        label: 'Personalize',
        value: true,
      },
    };
  }

  get personalizeTypes(): TransferItem[] {
    return [
      {
        key: 'active',
        title: 'Active',
      },
      {
        key: 'notFound',
        title: 'Not Found',
      },
      {
        key: 'expired',
        title: 'Expired',
      },
    ];
  }

  get newItem(): Status {
    return {
      created: Timestamp.now(),
      deleting: false,
      expires: null,
      passwordAuth: false,
      personalize: {},
      personalized: false,
      protected: false,
      revised: Timestamp.now(),
      session: this.globalState.get('userSession', 'sessionId'),
      unpublished: false,
    };
  }
}
