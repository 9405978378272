import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormArrayOptions, FormService } from '../../services/form';
import { FieldType } from '../../services/field';
import { FieldBase } from '../../block/services/block';
import { ColorType } from '../../services/color';

export interface Javascript extends FieldBase {
  background: {
    color: ColorType | undefined;
    opacity: number | undefined;
  };
  value: string;
}

export interface JavascriptOption {
  background: {
    color: boolean;
    opacity: boolean;
  };
  value: true;
}

export interface JavascriptOptions {
  options: JavascriptOption[];
  formArray: FormArrayOptions;
  newForm: (item: Javascript) => AbstractControl | undefined;
  newItem: Javascript;
  label: {
    placeholder: string;
    plural: string;
    singular: string;
  };
}

export interface JavascriptStyles {
  backgroundColor: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class JavascriptService {
  constructor(private formService: FormService) {}

  get newItem(): Javascript {
    return {
      background: {
        color: undefined,
        opacity: undefined,
      },
      fieldType: FieldType.JAVASCRIPT,
      value: '',
    };
  }

  newForm = (item: Javascript): AbstractControl | undefined => {
    return this.formService?.newForm(item);
  };

  get options(): JavascriptOptions {
    const options: JavascriptOptions = {
      options: [],
      newForm: this.newForm,
      newItem: this.newItem,
      label: {
        placeholder: 'javascript code',
        plural: 'Logiforms',
        singular: 'Logiform',
      },
      formArray: {
        max: 1,
        min: 0,
      },
    };
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        background: {
          color: false, // changing to true will also need a formControl
          opacity: true,
        },
        value: true,
      });
    }
    return options;
  }
}
