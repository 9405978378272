import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '../../environments/environment';
import { DOCUMENT, Location as Location_2 } from '@angular/common';
import { LOCATION, SESSION_STORAGE } from '@ng-web-apis/common';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { combineLatestWith, debounceTime, interval, takeWhile } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { Block } from '../block/services/block';
import { ScreenSize } from './style';
import { Image } from '../fields/image/service';
import { UserService, UserSession } from './user';
import { BlockState } from '../block/services/state';
import { WebEnvironment, WindowMetaData } from '../interfaces/window';
import { TemplateType } from '../options/template/service';
import { PersonalizeService } from '../options/personalize/service';
import { LinkService } from './link';
import { MemberAlertMessage } from './alert-message';
import { AdminAlert } from '../helpers/alerts/service';
import { IdTokenResultWithClaims } from './jwt-auth';
import { DebugService } from './debug';
import { compress as lzStringCompress, decompress as lzStringDecompress } from 'lz-string';
import { FormService } from './form';
import { Store } from '@ngrx/store';
import { WindowRefService } from '../modules/share/services/window-ref.service';
import { AppStore } from '@aaa/emember/store-types';
import { Environment } from '../../types';

export const GLOBAL_RX_STATE = new InjectionToken<RxState<GlobalState>>('GLOBAL_RX_STATE');

export interface BlockBackground {
  height: number;
  width: number;
  image: Image | undefined;
  url: string;
}

export interface GlobalState {
  afAuthIdTokenResult: firebase.auth.IdTokenResult | null;
  memberAlertMessages: MemberAlertMessage[];
  adminAlerts: AdminAlert[];
  allOldBlocksArray: Block[];

  adminMode: boolean;
  adminUser: boolean;
  blocks: { [key: string]: Block };
  blocksArray: Block[];
  blockStateIds: string[];
  blockStateIdsChanged: { [key: string]: boolean };
  blockTemplateTypesLoaded?: { [key: string]: boolean };
  createPanelRowIndex: number;
  createPanelVisible: boolean;
  debugMode: boolean;
  editorStateId: string;
  editorVisible: boolean;
  environment: Environment;
  hideAllBlockRows: boolean;
  lastUpdated: firebase.firestore.Timestamp;
  loadCreatePanel: boolean;
  location: Location;
  locationSearchArgs: LocationSearchArg[];
  modified: boolean;
  pageBlock: Block;
  pageWidth: number;
  previewMode: boolean;
  previewModeStyles: {
    margin: string;
    width: number;
  };
  previewSize: number;
  previewSizes: PreviewSizes;
  tokenRefresh: number;
  screenSize: ScreenSize;
  selectedField: string;
  sharedBlocks: { [key: string]: Block };
  sharedBlocksArray: Block[];
  showModal: string | undefined;
  site: {
    name: string;
  };
  templateEditor: boolean;
  templateEditSelected: Block;
  templateBlocks: { [key: string]: Block };
  templateBlocksArray: Block[];
  templateTypeSelected: TemplateType;
  templateTypes: { [key: string]: { label: string; type: TemplateType; templates: Block[] } };

  managingMode: boolean;

  refresh: string;
  userSession: UserSession;
  windowMetaData: WindowMetaData;
  version: { major: number; minor: number; patch: number };

  visibility: {
    [ScreenSize.MOBILE]: boolean;
    [ScreenSize.TABLET]: boolean;
    [ScreenSize.DESKTOP]: boolean;
    // PROMO_CODE_VALID: boolean
    // PROMO_CODE_INVALID: boolean
    // COUPON_CODE_VALID: boolean
    // COUPON_CODE_INVALID: boolean
    JOIN_SUCCESS: boolean;
  };

  windowScrollY: number;
}

export interface PreviewSizes {
  mobile: number;
  tablet: number;
  desktop: number;
}

export interface BlockForms {
  [key: string]: FormGroup | undefined;
}

export interface BlockStates {
  [key: string]: RxState<BlockState>;
}

export interface LocationSearchArg {
  key: string;
  value: string;
}

interface DefaultZipCode {
  [key: string]: string;
}

interface DefaultSiteName {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  states: BlockStates = {};
  retrievedZipcodeFromCookie: boolean = false;
  previousPathname: string;

  constructor(
    private debugService: DebugService,
    private userRequired: UserService, // loading user service, can be removed once we inject elsewhere
    private window: WindowRefService,
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(SESSION_STORAGE)
    private sessionStorage: Storage,
    @Inject(LOCATION)
    private location: Location,
    private location_2: Location_2,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private personalizeService: PersonalizeService,
    private rxEffects: RxEffects,
    private afs: AngularFirestore,
    private linkService: LinkService,
    private formService: FormService,
    private store: Store<AppStore>,
  ) {
    /**
     * An empty string for the default zipCode indicates that the club wants to hide pricing until the user enters a zip
     * One reason for this is when the club has different pricing for different regions
     *   - for example southpa (238) acquired the findlay region
     */
    const defaultZipCode: DefaultZipCode = {
      '023': '47868',
      '057': '',
      '071': '',
      '238': '',
    };
    if (this.globalState.get('environment', 'ngServe')) {
      defaultZipCode['057'] = '54321';
      defaultZipCode['071'] = '54321';
      defaultZipCode['238'] = '54321';
    }
    const defaultSiteName: DefaultSiteName = {
      '023': 'AAA Hoosier Motor Club',
      '057': '',
      '071': '',
      '238': '',
    };

    const windowMetaData = this.windowMetaData;
    if (windowMetaData.user.memberNumber === null) {
      windowMetaData.user.memberNumber = '';
    }

    const globalState_SessionStorageEnabled = false;

    let ssGlobalState: GlobalState = {} as GlobalState;
    const globalStateStored = sessionStorage.getItem('gState');
    // const ssStateMode = sessionStorage.getItem("stateMode")
    // let stateMode: string
    // if (ssStateMode) {
    //   stateMode = lzString.decompress(ssStateMode)
    //   console.log(stateMode)
    // }

    let debugMode: boolean = false;
    const modeItem: string | null = sessionStorage.getItem('mode');
    if (modeItem) {
      debugMode = lzStringDecompress(modeItem) === 'debug';
    }

    if (globalStateStored) {
      if (
        this.windowMetaData.user.id !== '1' &&
        !debugMode &&
        this.windowMetaData.webEnv.toUpperCase() === WebEnvironment.PROD
      ) {
        const globalStateString = lzStringDecompress(globalStateStored);
        if (globalStateString) {
          ssGlobalState = JSON.parse(globalStateString) as GlobalState;
        }
      }
      if (
        this.windowMetaData.user.id === '1' ||
        debugMode ||
        this.windowMetaData.webEnv.toUpperCase() !== WebEnvironment.PROD
      ) {
        ssGlobalState = this.parseJSONObject(globalStateStored) as GlobalState;
      }
    }
    // console.log(ssGlobalState)
    this.previousPathname = ssGlobalState?.location?.pathname;

    if (
      globalState_SessionStorageEnabled &&
      ssGlobalState &&
      ssGlobalState.userSession.sessionId &&
      ssGlobalState.lastUpdated.seconds + 3600 > firebase.firestore.Timestamp.now().seconds // 60 minute expiration
    ) {
      ssGlobalState.adminMode = false;
      ssGlobalState.afAuthIdTokenResult = {} as IdTokenResultWithClaims;
      ssGlobalState.windowMetaData = this.windowMetaData;
      ssGlobalState.pageWidth = window.nativeWindow.innerWidth; // use window.innerWidth only for initializing
      ssGlobalState.environment = environment;
      globalState.set(ssGlobalState);

      console.log('using sessionStorageGlobalState');
      console.log(ssGlobalState);
    } else {
      globalState.set({
        adminMode: false,
        adminUser: false,
        afAuthIdTokenResult: {} as IdTokenResultWithClaims,
        debugMode: debugMode,
        memberAlertMessages: [],
        environment: environment,
        hideAllBlockRows: false,
        lastUpdated: firebase.firestore.Timestamp.now(),
        pageWidth: window.nativeWindow.innerWidth, // use window.innerWidth only for initializing
        previewSizes: this.previewSizes,
        site: {
          name: defaultSiteName[this.windowMetaData.clubId],
        },
        tokenRefresh: Date.now(),
        userSession: {
          couponCode: '',
          promoCode: '', // ["ISFD22"],
          sessionId: afs.createId(),
          zipcode: this.windowMetaData.user.zipcode || defaultZipCode[this.windowMetaData.clubId],
        },
        windowMetaData: this.windowMetaData,
      });
    }

    const state = globalState.get();

    // this.store.dispatch(SessionActions.setId({ sessionId: state.userSession.sessionId }))
    // this.store.dispatch(MembershipActions.setZipcode({ zipcode: state.userSession.zipcode }))
    // this.store.dispatch(MembershipActions.initialize())
    // this.store.dispatch(
    //   PricePreviewActions.load({
    //     payload: { promoCode: getLocationQueryParams("").promo || "" },
    //   }),
    // )

    linkService.processUrlPathname();
    linkService.processLocationSearch();

    /**
     * location_2 (from angular/common) emits on events like the browser back/forward buttons
     *   whereas location (from ng-web-apis/common) does not
     * and vice-versa, location emits on page load whereas location_2 does not
     */
    location_2.subscribe((location) => {
      linkService.processUrlPathname();
      linkService.processLocationSearch();
    });

    rxEffects.register(this.rolesSideEffects$);
    rxEffects.register(this.globalState$);
    rxEffects.register(this.adminMode$);
    if (!this.globalState.get('environment', 'ngServe')) {
      rxEffects.register(this.zipcodeFromCookie$);
    }
  }

  zipcodeFromCookie$ = interval(1000).pipe(
    takeWhile(() => !this.retrievedZipcodeFromCookie),
    tap((timer) => {
      if (this.zipCodeFromCookie()) {
        this.retrievedZipcodeFromCookie = true;
        this.globalState.set('userSession', (oldState) => {
          oldState.userSession.zipcode = this.zipCodeFromCookie();
          return oldState.userSession;
        });
      }
    }),
  );

  adminMode$ = this.globalState.select('adminMode').pipe(
    combineLatestWith(this.globalState.select('adminUser')),
    tap(([adminMode, adminUser]) => {
      if (!adminUser) {
        // this.sessionStorage.setItem("stateMode", lzString.compress("obfuscate"))
        if (adminMode) {
          this.globalState.set('adminMode', () => false);
        }
      }
    }),
  );

  globalState$ = this.globalState.select().pipe(
    debounceTime(3000),
    tap((globalState) => {
      const stateStorage: GlobalState = {} as GlobalState;
      for (const key of Object.keys(globalState)) {
        switch (key) {
          case 'memberAlertMessages':
            break;
          case 'lastUpdated':
            stateStorage[key as keyof GlobalState] = firebase.firestore.Timestamp.now() as never;
            break;
          default:
            stateStorage[key as keyof GlobalState] = globalState[key as keyof GlobalState] as never;
        }
      }

      const stringifyStateStorage = JSON.stringify(stateStorage);

      if (
        !this.globalState.get('adminUser') &&
        !this.globalState.get('debugMode') &&
        this.globalState.get('windowMetaData', 'webEnv').toUpperCase() === WebEnvironment.PROD
      ) {
        // console.log("not adminUser gState")
        this.sessionStorage.setItem('gState', lzStringCompress(stringifyStateStorage));
      }
      if (
        this.globalState.get('adminUser') ||
        this.globalState.get('debugMode') ||
        this.globalState.get('windowMetaData', 'webEnv').toUpperCase() !== WebEnvironment.PROD
      ) {
        this.sessionStorage.setItem('gState', stringifyStateStorage);
      }
    }),
  );

  rolesSideEffects$ = this.globalState.select('afAuthIdTokenResult').pipe(
    tap((afAuthIdTokenResult) => {
      if (!afAuthIdTokenResult?.claims?.roles) {
        this.globalState.set('adminMode', () => false);
      }
    }),
  );

  parseJSONObject(jsonString: string): object | boolean {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  /*
  registerFormAndBlockState(stateId: string, form?: FormGroup, blockState?: RxState<BlockState>): void {
    if (form) {
      this.forms[stateId] = form
    }
    if (blockState) {
      this.states[stateId] = blockState
    }
    if (form || blockState) {
      this.globalState.set("blockStateIds", (globalState) => globalState.blockStateIds.concat([stateId]))
    }
  }
*/

  get windowMetaData(): WindowMetaData {
    return this.window.nativeWindow['metaData'];
  }

  get previewSizes(): PreviewSizes {
    return {
      mobile: 320,
      tablet: 700,
      desktop: 1200,
    };
  }

  /*
  resetFormsAndBlockStates(): void {
    this.forms = {}
    this.states = {}
    this.globalState.set("blockStateIds", () => [])
  }
*/

  compareAllBlockStates(): void {
    for (const stateId of this.globalState.get('blockStateIds')) {
      const block = this.states[stateId]?.get('block') as Block;
      const blockEditRevision = this.states[stateId]?.get('blockEditRevision') as Block;
      if (block && blockEditRevision) {
        this.compareBlockAndEditRevision(block, blockEditRevision, stateId);
      }
    }
  }

  /**
   * Compare public-block with edit-revision form.
   * set modified flags of not equal
   */
  compareBlockAndEditRevision(block: Block, editRevision: Block, stateId: string) {
    const blocksNotEqual = this.blocksNotEqual(block, editRevision);

    const blockStateIdsChanged = this.globalState.get('blockStateIdsChanged') || {};
    blockStateIdsChanged[stateId] = blocksNotEqual;

    /**
     * set global modified flag true if any of the modified blocks are not templates
     * otherwise set to false
     */
    let modifiedGlobal = false;
    for (const modifiedBlockKey in blockStateIdsChanged) {
      if (blockStateIdsChanged[modifiedBlockKey] && !this.globalState.get('blocks')[modifiedBlockKey]?.blockTemplate) {
        modifiedGlobal = true;
      }
    }

    this.states[block.id]?.set('modified', () => blocksNotEqual);
    this.globalState.set({
      modified: modifiedGlobal,
      blockStateIdsChanged: blockStateIdsChanged,
    });
  }

  /**
   * remove known differences
   * then compare blocks
   */
  blocksNotEqual(block1: Block, block2: Block): boolean {
    if (Object.keys(block1).length && Object.keys(block2).length) {
      /**
       * TODO: add a delay to batch these, maybe 1500ms.
       */
      const blockCopy1 = JSON.parse(JSON.stringify(block1));
      const blockCopy2 = JSON.parse(JSON.stringify(block2));
      /**
       * synchronize "status.session" for the comparison
       * we expect it to be different
       * we don't want to flag if they are different
       */
      delete blockCopy1.status.session;
      delete blockCopy2.status.session;
      delete blockCopy1.status.created;
      delete blockCopy2.status.created;
      delete blockCopy1.status.revised;
      delete blockCopy2.status.revised;
      delete blockCopy1.status.expires;
      delete blockCopy2.status.expires;
      // delete this.state[stateId].form.value.id

      /**
       * don't remember why visibleOptions mod is here, disabling for now
       */
      // const visibleOptions = blockCopy2.options?.visible
      // for (const option in visibleOptions) {
      //   blockCopy1.options.visible[option] = blockCopy1.options.visible[option] || false
      // }

      return this.notDeepEqual(blockCopy1 as never, blockCopy2 as never);
    }
    /**
     * TODO
     * this should be an error of some kind,
     * not equal is not the same as invalid input
     */
    return true;
  }

  notDeepEqual(object1: Block, object2: Block): boolean {
    if (!object1 || !object2) {
      return true;
    }
    if (Object.keys(object1).length !== Object.keys(object2).length) {
      return true;
    }
    for (const key of Object.keys(object1)) {
      const val1 = object1[key as keyof Block];
      const val2 = object2[key as keyof Block];
      const areObjects = val1 != null && typeof val1 === 'object' && val2 != null && typeof val2 === 'object';
      if ((areObjects && this.notDeepEqual(val1 as Block, val2 as Block)) || (!areObjects && val1 !== val2)) {
        return true;
      }
    }
    return false;
  }

  /*
  deepEqual(object1: Block, object2: Block): boolean {
    if (!object1 || !object2) {
      return false
    }
    if (Object.keys(object1).length !== Object.keys(object2).length) {
      return false
    }
    for (const key of Object.keys(object1)) {
      const val1 = object1[key as keyof Block]
      const val2 = object2[key as keyof Block]
      const areObjects = (val1 != null) && (typeof val1 === "object") && (val2 != null) && (typeof val2 === "object")
      if ((areObjects && !this.deepEqual((val1 as Block), (val2 as Block))) || (!areObjects && (val1 !== val2))) {
        return false
      }
    }
    return true
  }
*/

  /**
   * helpers
   */

  zipCodeFromCookie(): string {
    return (
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('zipcode='))
        ?.split('=')[1]
        ?.split('|')[0] || ''
    );
  }

  /**
   * Start with this.block, then narrow down to the value of the "path" part of the block.
   * however forEach is bad
   */
  /*
  let blockValue = block
  path.forEach(pathPart => blockValue = blockValue?.[pathPart])
  */
}
