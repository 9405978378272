import { Injectable } from '@angular/core';
import { TransferItem } from 'ng-zorro-antd/transfer';

export interface Personalize {
  [key: string]: boolean;
}

export type PersonalizeOption = 'active' | 'notFound' | 'expired';
export type PersonalizeTypes = Record<PersonalizeOption, TransferItem>;

export interface MembershipLevel {
  key: string;
  title: string;
}

export type MembershipLevelOptions = 'basic' | 'plus' | 'plusRV' | 'plusMotorcycle' | 'premier' | 'premierRV';
export type MembershipLevels = Record<MembershipLevelOptions, MembershipLevel>;

export interface MembershipStatusDeprecated {
  key: string;
  title: string;
}

export type MembershipStatusOptions = 'active' | 'pending' | 'expired' | 'cancelled' | 'notFound';
export type MembershipStatuses = Record<MembershipStatusOptions, MembershipStatusDeprecated>;

@Injectable({
  providedIn: 'root',
})
export class PersonalizeService {
  get membershipLevels(): MembershipLevels {
    return {
      basic: {
        key: 'AAA Basic',
        title: 'AAA Basic',
      },
      plus: {
        key: 'AAA Plus',
        title: 'AAA Plus',
      },
      plusRV: {
        key: 'AAA Plus RV',
        title: 'AAA Plus RV',
      },
      plusMotorcycle: {
        key: 'AAA Plus Motorcycle',
        title: 'AAA Plus Motorcycle',
      },
      premier: {
        key: 'AAA Premier',
        title: 'AAA Premier',
      },
      premierRV: {
        key: 'AAA Premier RV',
        title: 'AAA Premier RV',
      },
    };
  }

  get membershipStatuses(): MembershipStatuses {
    return {
      active: {
        key: 'active',
        title: 'Active',
      },
      pending: {
        key: 'pending',
        title: 'Pending',
      },
      expired: {
        key: 'expired',
        title: 'Expired',
      },
      cancelled: {
        key: 'cancelled',
        title: 'Cancelled',
      },
      notFound: {
        key: 'not_found',
        title: 'Not Found',
      },
    };
  }
}
