import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldType } from '../../../../../services/field';
import { FormService } from '../../../../../services/form';
import { FieldBase } from '../../../../../block/services/block';

export interface PriceChartMemberQuantity extends FieldBase {
  // label?: string
}

@Injectable({
  providedIn: 'root',
})
export class PriceChartMemberQuantityService {
  constructor(private formService: FormService) {}

  get newItem(): PriceChartMemberQuantity {
    return {
      fieldType: FieldType.PRICE_CHART_MEMBER_QUANTITY,
    };
  }

  newForm = (item: PriceChartMemberQuantity): AbstractControl | undefined => {
    return this.formService.newForm(item);
  };
}
