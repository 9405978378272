import { AnalyticsEvent } from '../services/analytics';
import { ClubApp } from '@aaa/emember/types';

export interface MetaWindow {
  metaData: WindowMetaData;
  location: {
    href: string;
    origin: string;
    pathname: string;
    search: string;
  };
  pageXOffset: number;
  pageYOffset: number;
  screen: {
    width: number;
  };
  screenX: number;
  screenY: number;
  open(url: string, windowName: string, windowFeatures: string): void;
  ga(send: string, event1: string, event?: AnalyticsEvent | string): void;
}

export interface WindowMetaData {
  appMode?: boolean;
  clubId: string | ClubApp;
  origin: string;
  webEnv: WebEnvironment;
  gaId?: string;
  gaClientId?: string;
  servicesTokenCSRF?: string;
  user: {
    id: string;
    token: string;
    email: string;
    memberNumber: string;
    firstName: string;
    lastName: string;
    roles: string[];
    status: string;
    zipcode: string;
  };
  editor?: boolean;
  testMode?: boolean;
}

export enum WebEnvironment {
  TEST = 'TEST',
  PROD = 'PROD',
  LOCAL = 'LOCAL',
  APP_TEST = 'APP_TEST',
}
