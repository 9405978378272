import { Injectable } from '@angular/core';
import { DataReadService } from './data-read';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Block } from '../block/services/block';
import WriteBatch = firebase.firestore.WriteBatch;
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class RevisionWriteService {
  deleteCounter: { [key: string]: number } = {};

  constructor(
    private afs: AngularFirestore,
    private dataReadService: DataReadService,
  ) {}

  updateEditRevisions(blocks: Block[]): void {
    const batch = this.afs.firestore.batch();
    this.updateEditRevisionsBatch(batch, blocks);
    batch.commit().then();
  }

  updateEditRevisionsBatch(batch: WriteBatch, blocks: Block[]): WriteBatch {
    for (const block of blocks) {
      if (block) {
        block.status.expires = Timestamp.fromMillis(Timestamp.now().toMillis() + 30 * 24 * 60 * 1000); // 30 day expiration
        batch.set(this.dataReadService.revisionRef.doc(block.id).ref, block);
      }
    }
    return batch;
  }

  deleteAllEditRevisions(): void {
    /*
    for (const blockId in this.dataReadService.blocks) {
      this.deleteEditRevision(blockId)
    }
*/
  }

  deleteEditRevision(blockId: string): void {
    /*
    this.deleteCounter[blockId] = this.deleteCounter[blockId] ? this.deleteCounter[blockId] + 1 : 1

    if (this.deleteCounter[blockId] < 5) {
      this.dataReadService.editRevisionRef(blockId)
        .delete()
        .then()
    }
*/
  }
}
