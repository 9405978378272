import { Inject, Injectable } from '@angular/core';
import { RxEffects } from '@rx-angular/state/effects';
import { GLOBAL_RX_STATE, GlobalState } from './state';
import { RxState } from '@rx-angular/state';
import { tap } from 'rxjs';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { compress as lzStringCompress, decompress as lzStringDecompress } from 'lz-string';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  constructor(
    @Inject(SESSION_STORAGE)
    private sessionStorage: Storage,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
  ) {
    rxEffects.register(this.debugMode$);
  }

  /**
   * read debug argument from url
   *   ?debug=true
   *   ?debug=false
   * set sticky boolean for debug mode in session storage
   */
  debugMode$ = this.globalState.select('locationSearchArgs').pipe(
    tap((locationSearchArgs) => {
      const mode = locationSearchArgs
        .find((locationSearchArg) => locationSearchArg.key.toLowerCase() === 'mode')
        ?.value?.toLowerCase();
      if (mode) {
        this.sessionStorage.setItem('mode', lzStringCompress(JSON.stringify(mode)));
        if (mode === 'debug') {
          this.globalState.set('debugMode', () => true);
        }
        if (mode !== 'debug') {
          this.globalState.set('debugMode', () => false);
        }
      }
    }),
  );
}
